import { createApp } from 'vue';
import { createPinia } from 'pinia'
import App from './App.vue';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/dist/index.css'; 
import Antd from 'ant-design-vue';
import router from './router'


const pinia = createPinia()
const app = createApp(App);
app.use(router);
app.use(Antd);
app.use(ElementPlus, {locale:zhCn});
app.use(pinia)
app.mount('#app');
