import request from '../utils/request'



// 定义请求函数，首页请求刷新
export function homePageApi() {
    return request({ // 返回request请求
        url: `/xhh/homePage/loadUserInfo`, // 请求接口的地址
        method: 'get', // 请求方式是get 还有get等方法(了解)
    })
}

// 定义请求函数，退出登录
export function logoutApi(ssoToken: string) {
    return request({ // 返回request请求
        url: `xhh/sso/ssoLogout`, // 请求接口的地址
        method: 'get', // 请求方式是get 还有get等方法(了解)
        params: {ssoToken} 
    })
}

// 定义请求函数：订单列表获取
export function getOrderListApi(postData: object, pageSize: number, currentPage: number) {
    return request({ // 返回request请求
        url: `/xhh/internation/order/list/${pageSize}/${currentPage}`, // 请求接口的地址
        method: 'post', // 请求方式是post 还有get等方法(了解)
        data: postData  // 请求的数据是params
    })
}

// 定义请求函数：航班列表获取
export function getFlightInfoListApi(postData: object) {
    return request({ // 返回request请求
        url: `/xhh/internation/search`, // 请求接口的地址
        method: 'post', // 请求方式是post 还有get等方法(了解)
        data: postData  // 请求的数据是params
    })
}

// 定义请求函数：Verify接口
export function verifyApi(param: string) {
    return request({ // 返回request请求
        url: `/xhh/internation/verify?${param}`, // 请求接口的地址
        method: 'get', // 请求方式是get 还有get等方法(了解)
    })
}

// 定义请求函数：Order接口
export function orderApi(postData: object) {
    return request({ // 返回request请求
        url: `/xhh/internation/order`, // 请求接口的地址
        method: 'post', // 请求方式是post 还有get等方法(了解)
        data: postData  // 请求的数据是params
    })
}

// 定义请求函数：获取国籍二字码
export function getNationalityApi() {
    return request({ // 返回request请求
        url: `/xhh/commont/nationalitySuggestdb`, // 请求接口的地址
        method: 'get', // 请求方式是get 还有get等方法(了解)
    })
}

// 定义请求函数：获取机场三字码
export function getAirPortCodeApi() {
    return request({ // 返回request请求
        url: `/xhh/commont/airPortDb`, // 请求接口的地址
        method: 'get', // 请求方式是get 还有get等方法(了解)
    })
}

// 定义请求函数：根据订单获取详情
export function getOrderDetailApi(orderNo: string) {
    return request({ // 返回request请求
        url: `/xhh/internation/order/${orderNo}`, // 请求接口的地址
        method: 'post', 
    })
}


