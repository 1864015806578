// 引入axios封装
import axios from 'axios'
import { message } from 'ant-design-vue'

// 生产后端URL：http://115.29.235.52:61201
// 测试URL：http://192.168.1.118:61201

// 创建axios实例
const request = axios.create({
    baseURL: 'https://flight.xhhtrip.com/api', // url = base url + request url
    timeout: 120000, // 120s超时
})

// 请求拦截器 一般写法模式
request.interceptors.request.use(
    (config) => {
        const ssoToken = localStorage.getItem('ssoToken');
        if(ssoToken) {
            // 设置自定义请求头
            config.headers['ssoToken'] = ssoToken;
        }
        return config;
    },
    (error) => { // 请求失败则显示错误状态
        message.error(error.message)
        return Promise.reject(error)
    }
)

// 响应拦截器
request.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (error.response && error.response.status === 403) {
            // 清理本地存储的用户数据
            localStorage.removeItem("ssoToken");
            // 重定向到登录页面
            const loginUrl = "http://sso.xhhtrip.com/login.htm?originalUrl=https://flight.xhhtrip.com";  // 生产URL
            //const loginUrl = "http://sso.xhhtrip.com/login.htm?originalUrl=http://192.168.1.121:8080/#/business/reservation";        // 测试URL
            window.location.href = loginUrl;
        } else {
            // 其他错误处理
            message.error(error.message);
        }
        return Promise.reject(error);
    }
)
// 导出request
export default request 