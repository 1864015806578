// 导入一个方法 defineStore
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const getUserStore = defineStore('user', () => {
    // 定义数据
    const userName = ref("");

    // 定义修改数据的方法(action 同步+异步)
    const setUserName = () => {
        const name = localStorage.getItem("userName");
        if (name !== null) {
            userName.value = name;
        }
    }

    // 以对象的方式return供组件使用
    return {
        userName,
        setUserName
    }
})