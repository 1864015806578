import { createRouter, createWebHistory } from "vue-router";

// 定义路由
const routes = [
    {
        path: "/",
        redirect: "/business/reservation"  // 填ip+端口，可以直接跳转到首页
    },
    {
        path: "/business/home",
        component: () => import("../components/HomePage.vue"),
        name: "home",
        meta: { breadcrumb: '首页' }, // 面包屑信息
    },
    {
        path: "/business/reservation",
        component: () => import("../components/QueryReservation.vue"),
        name: "reservation",
        meta: { breadcrumb: '机票预定' }
    },
    {
        path: "/business/agent",
        component: () => import("../components/MyOrder.vue"),
        name: "agent",
        meta: { breadcrumb: '我的订单' }
    },
    {
        path: "/business/detail",
        component: () => import("../components/ReservationDetail.vue"),
        name: "detail",
        meta: { breadcrumb: '预定详情' }
    },
    {
        path: "/business/order",
        component: () => import("../components/OrderDetail.vue"),
        name: "order",
        meta: { breadcrumb: '订单详情' }
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});


// 全局前置守卫
router.beforeEach((to, from, next) => {
    // 从 URL 查询参数中获取 ssoToken 值
    let ssoToken = to.query.ssoToken;
    if(!ssoToken) {
        // 使用 window.location.search 获取查询参数
        const queryParams = window.location.search;
        const match = queryParams.match(/[?&]ssoToken=([^&]*)/);
        ssoToken = match && decodeURIComponent(match[1]);
        // 移除 URL 中的 ssoToken 参数，不会刷新页面
        removeQueryParam("ssoToken");
    }
    
    // 登录判断逻辑
    if (!ssoToken) {
        ssoToken = localStorage.getItem('ssoToken');
    }
    if (ssoToken) {
        // 将获取到的令牌ssoToken存入浏览器中
        localStorage.setItem('ssoToken', ssoToken.toString());
        // 用户已登录，调用 next() 继续路由导航
        next();
    } else {
        // 用户未登录，跳转到登录页面
        const loginUrl = "http://sso.xhhtrip.com/login.htm?originalUrl=https://flight.xhhtrip.com";  // 生产URL
        //const loginUrl = "http://sso.xhhtrip.com/login.htm?originalUrl=http://192.168.1.121:8080/#/business/reservation";        // 测试URL
        window.location.href = loginUrl;
    }

});

// 移除 URL 查询参数的函数
const removeQueryParam = (paramName: string) => {
    const url = window.location.href;
    const regex = new RegExp(`[?&]${paramName}=([^&]*)`, 'g');
    const newUrl = url.replace(regex, '');
    window.history.replaceState({}, '', newUrl);
}

export default router